export type Maybe<T> = T | null;

export type QueryGetUsersPaginationArgs = {
  input: GetUsersPaginationInput;
};

export type QueryAuth0InputArgs = {
  input: Auth0UsersInput;
};

export type QueryFindAllAccountsArgs = {
  input: FindAllAccountsInput;
};

export type QueryGetUserByIdArgs = {
  id: string;
};

export type MutationSeamlessVerificationArgs = {
  input: {
    username: string;
    authToken: string;
    ehrUrl: string;
    ehrSystem: string;
  };
};

export type MutationDeleteAuth0UsersArgs = {
  input: { userIds: Array<string> };
};

export type QueryGetAuditsArgs = {
  input: GetAuditInput;
};

export type FindAllAccountsResult = SlimAccountResp[];

export type SlimAccountResp = {
  id: number;
  name: string;
  relations?: { relationType: string; relatedAccountId: number }[];
  dataSource?: { dataSourceEnum: string };
  config?: { enableCDEForOrg: boolean };
};

export enum AccountRelationType {
  ConsumesContentFrom = 'consumesContentFrom',
  ManagedBy = 'managedBy',
}

export type GetAllOrganizationsResult = {
  count?: Maybe<number>;
  results?: Maybe<Array<Maybe<Organization>>>;
};

export type Organization = {
  id?: Maybe<number>;
  name?: Maybe<string>;
  ehrSystem?: Maybe<string>;
  ehrUrl?: Maybe<string>;
  ehrOS?: Maybe<Os>;
  ehrIdentifier?: Maybe<string>;
  config?: Maybe<any>;
  products?: Maybe<Array<OrganizationProduct>>;
  namespaceName?: Maybe<string>;
  slug?: Maybe<string>;
  identityProvider?: Maybe<IdentityProvider>;
  organizationKey?: Maybe<string>;
  tag?: Maybe<Tags>;
  theme?: Maybe<OrganizationTheme>;
  isSeamlessLoginAutoApproved?: Maybe<boolean>;
  isPopupsEnabled?: Maybe<boolean>;
  isLts?: Maybe<boolean>;
  ssoConnectionName?: Maybe<string>;
  tin?: Maybe<string>;
  tins?: Maybe<Array<Maybe<string>>>;
  npi?: Maybe<string>;
  accountId?: Maybe<number>;
};

export type ReducedAuth0User = {
  user_id: string;
  name: string;
  email: string;
  connection: string;
  created_at: string;
  logins_count: number;
  tableData?: { id: number; checked: boolean };
};

export enum Os {
  Desktop = 'desktop',
  Web = 'web',
  Sdk = 'sdk',
}

export type OrganizationProduct = {
  name: string;
  displayName?: string;
  sources?: Maybe<Array<Maybe<string>>>;
  providerSources?: Maybe<Array<Maybe<string>>>;
  contentSources?: Maybe<Array<Maybe<string>>>;
  isDefaultVcUserProduct?: Maybe<boolean>;
};

export enum IdentityProvider {
  Auth0 = 'Auth0',
  Auth0InteractiveSso = 'Auth0InteractiveSSO',
  SeamlessLogin = 'SeamlessLogin',
}

export enum OrganizationTheme {
  VimConnect = 'vimConnect',
  Optum = 'optum',
  Aledade = 'aledade',
  MaxHealth = 'maxHealth',
  MockCanvasApp = 'mockCanvasApp',
  Agilon = 'agilon',
  Kahun = 'kahun',
  PriviaInsights = 'priviaInsights',
  OfficeAlly = 'officeAlly',
  Elation = 'elation',
}
export interface Application {
  id: string;
  displayName: string;
  configUrl?: string;
  supportedDataSources: ApplicationDataSource[];
}

export type ApplicationDataSource = {
  id: number;
  accountId: number;
  name: string;
  dataSourceEnum: string;
  namespace: string;
};

export type Product = {
  id: string;
  name?: string;
  application: string;
  sources: Array<string>;
  providerSources: Array<string>;
  contentSources?: Array<string>;
  configUrl?: string;
};

export type Provider = {
  id: string;
  orgId: number;
  createdAt?: Maybe<string>;
  updatedAt?: Maybe<string>;
  npi: string;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  specialty?: Maybe<Array<Maybe<string>>>;
  tin?: Maybe<string>;
  address?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  zip?: Maybe<string>;
  addressLine1?: Maybe<string>;
};

export type User = {
  createdAt?: Maybe<string>;
  activatedAt?: Maybe<string>;
  id?: Maybe<string>;
  username?: Maybe<string>;
  email?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  blocked?: Maybe<boolean>;
  ehrUser?: Maybe<EhrUser>;
  connection?: Maybe<string>;
  isPasswordExpired?: Maybe<boolean>;
  isOrgAdmin?: Maybe<boolean>;
  lastLoginAt?: Maybe<string>;
};

export type EhrUser = {
  organization?: Maybe<Organization>;
  applications: Array<ApplicationWithConfig>;
  products: Array<UserProduct>;
  npi?: Maybe<string>;
  tin?: Maybe<Array<Maybe<string>>>;
  title?: Maybe<string>;
  titles?: Maybe<string[]>;
  roles?: Maybe<string[]>;
  address?: Maybe<Address>;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  linkedEhrUser?: Maybe<string>;
  tag?: Maybe<Tags>;
};

export type ApplicationWithConfig = {
  name?: Maybe<string>;
  config?: Maybe<any>;
};

export type UserProduct = {
  name: string;
  sources?: Maybe<Array<Maybe<string>>>;
};

export type Address = {
  addressLine1?: Maybe<string>;
  addressLine2?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  zip?: Maybe<string>;
  fullAddress?: Maybe<string>;
};

export enum Tags {
  VimTest = 'VimTest',
  AppTest = 'AppTest',
  ClinicTest = 'ClinicTest',
}

export type GetUsersPaginationInput = {
  page?: Maybe<number>;
  perPage?: Maybe<number>;
  q?: Maybe<string>;
  filter?: Maybe<string>;
  orderDirection?: Maybe<string>;
  orderBy?: Maybe<string>;
};

export type GetUsersPaginationResult = {
  users: Array<User>;
  count?: Maybe<number>;
};

export enum Auth0UsersQueryType {
  Zombies = 'Zombies',
}

export type Auth0UsersInput = {
  type: Auth0UsersQueryType;
};

export type FindAllAccountsInput = {
  omitProviderOrganizationsData?: boolean;
  omitDataSourceData?: boolean;
  omitApplicationsData?: boolean;
  options?: FindAllAccountsOptionsEnum[];
};

enum FindAllAccountsOptionsEnum {
  DATA_SOURCE = 'dataSource',
  PROVIDER_ORGANIZATION = 'providerOrganizations',
}

export type Auth0UsersResult = {
  users: Array<ReducedAuth0User>;
};

export type DeleteAuth0UsersResult = {
  userIds: Array<string>;
};

export type GetUserByIdResult = User;

export type GetAuditInput = {
  q?: Maybe<string>;
  filter?: Maybe<string>;
  filters?: Maybe<Record<string, unknown>>;
  page?: Maybe<number>;
  limit?: Maybe<number>;
  order?: Maybe<string>;
  sortBy?: Maybe<string>;
};

export type MutationRemoveProductsFromOrganizationUsersArgs = {
  input: RemoveProductsFromOrganizationUsersInput;
};

export type MutationAddProductsToOrganizationUsersArgs = {
  input: AddProductsToOrganizationUsersInput;
};

export type MutationCreateOrUpdateProviderOrganizationArgs = {
  input: OrganizationInput & { accountId?: number };
};

export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

export type MutationUpsertProviderArgs = {
  input: ProviderInput;
};

export type MutationDeleteProviderArgs = {
  id: string;
};

export type MutationDeleteAllProvidersArgs = {
  id?: Maybe<number>;
};

export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationImportUsersArgs = {
  input: ImportUsersInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationCreateConsoleUserArgs = {
  input: CreateConsoleUserInput;
};

export type MutationDeleteConsoleUserArgs = {
  input: DeleteConsoleUserInput;
};

export type MutationUpdateUserLinkedEhrUserArgs = {
  input: UpdateUserLinkedEhrUser;
};

export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmail;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationResendActivationMailArgs = {
  input: ResendActivationMailInput;
};

export type MutationUpdateOrgUsersTinArgs = {
  input: UpdateOrgUsersTinInput;
};

export interface IsEhrSupportSeamlessArgs {
  input: string;
}

export interface IsSeamlessAvailableInput {
  ehr: string;
  url: string;
}

export interface IsSeamlessAvailableArgs {
  input: IsSeamlessAvailableInput;
}

export interface IsSeamlessAvailableResult {
  isReachable: boolean;
  isSslValid: boolean;
}

export type RemoveProductsFromOrganizationUsersInput = {
  orgId?: Maybe<number>;
  productsToRemove?: Maybe<Array<Maybe<string>>>;
};

export type AddProductsToOrganizationUsersInput = {
  orgId?: Maybe<number>;
  newProducts?: Maybe<Array<Maybe<string>>>;
};

export type CreateAccountInput = {
  name: Maybe<string>;
  address?: Maybe<string>;
  tin?: Maybe<string>;
  dataSource?: Maybe<DataSourceInput>;
  providerOrganizations?: Maybe<Array<ProviderOrganizationInput>>;
  activationStatus?: Maybe<AccountActivationStatus>;
};

export type DataSourceInput = {
  tin: Maybe<string>;
  dataSourceEnum: Maybe<string>;
  name: Maybe<string>;
};

export type ProviderOrganizationInput = OrganizationInput;

export type OrganizationInput = {
  id?: Maybe<number>;
  name?: Maybe<string>;
  slug?: Maybe<string>;
  ehrSystem?: Maybe<string>;
  ehrOS?: Maybe<Os>;
  ehrUrl?: Maybe<string>;
  config?: Maybe<any>;
  products?: Maybe<Array<Maybe<OrganizationProductInput>>>;
  namespaceName?: Maybe<string>;
  identityProvider?: Maybe<string>;
  organizationKey?: Maybe<string>;
  tag?: Maybe<Tags>;
  theme?: Maybe<OrganizationTheme>;
};

export type OrganizationProductInput = {
  name: string;
  sources?: Maybe<Array<Maybe<string>>>;
  providerSources?: Maybe<Array<Maybe<string>>>;
  contentSources?: Maybe<Array<Maybe<string>>>;
};

export type AddressInput = {
  addressLine1?: Maybe<string>;
  addressLine2?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  zip?: Maybe<string>;
};

export type ProviderInput = {
  orgId: number;
  npi: string;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  specialty?: Maybe<Array<Maybe<string>>>;
  tin?: Maybe<string>;
  address?: Maybe<string>;
};

export type CreateAdminInput = {
  email: string;
  password?: Maybe<string>;
};

export type CreateConsoleUserInput = {
  accountId: number;
  email: string;
  username?: Maybe<string>;
  password?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  tag?: Maybe<Tags>;
  address?: Maybe<AddressInput>;
  externalRoles?: Maybe<ExternalRole[]>;
  vcUserMetadata?: Maybe<
    Omit<UpdateUserInput, 'externalId'> & {
      organizationId?: Maybe<number>;
      externalId?: Maybe<string>;
      metadata?: Maybe<CreateUserInputMetadata>;
      linkedEhrUser?: Maybe<string>;
    }
  >;
  permittedOrganizations: {
    all: true;
  };
};

export type DeleteConsoleUserInput = {
  accountId: number;
  externalId: string;
  deletionReason?: Maybe<string>;
};

export type CreateUserInput = {
  email?: Maybe<string>;
  username?: Maybe<string>;
  password?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  organizationId: number;
  products?: Maybe<Array<Maybe<string>>>;
  npi?: Maybe<string>;
  tin?: Maybe<Array<Maybe<string>>>;
  title?: Maybe<string>;
  titles?: Maybe<string[]>;
  roles?: Maybe<string[]>;
  address?: Maybe<AddressInput>;
  firstName: string;
  lastName: string;
  linkedEhrUser?: Maybe<string>;
  tag?: Maybe<Tags>;
  isOrgAdmin?: Maybe<boolean>;
  metadata?: Maybe<CreateUserInputMetadata>;
};

export type CreateUserInputMetadata = {
  createType?: Maybe<UserCreateType>;
};

export enum UserCreateType {
  CONSOLE = 'console',
  POTENTIAL = 'potential',
  IMPORT = 'import',
  EHR_AUTO = 'ehr_auto',
}

export enum ExternalRole {
  VIM_ADMIN = 'VIM_ADMIN',
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  ACCOUNT_OWNER = 'ACCOUNT_OWNER',
  ORG_ADMIN = 'ORG_ADMIN',
}

export type ImportedUserInput = {
  email?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  organizationId: number;
  products?: Maybe<Array<Maybe<string>>>;
  npi?: Maybe<string>;
  tin?: Maybe<Array<Maybe<string>>>;
  title?: Maybe<string>;
  titles?: Maybe<string[]>;
  roles?: Maybe<string[]>;
  address?: Maybe<AddressInput>;
  firstName: string;
  lastName: string;
  linkedEhrUser?: Maybe<string>;
  tag?: Maybe<Tags>;
  isOrgAdmin?: Maybe<boolean>;
};

export type ImportUsersInput = {
  importedUsers: Array<Maybe<ImportedUserInput>>;
  organizationId: number;
  fileName: string;
  requestingUser: string;
};

export type SuccessStatus = {
  success: boolean;
};

export type UpdateUserInput = {
  externalId: string;
  username?: Maybe<string>;
  password?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  blocked?: Maybe<boolean>;
  products?: Maybe<Array<Maybe<string>>>;
  npi?: Maybe<string>;
  tin?: Maybe<Array<Maybe<string>>>;
  title?: Maybe<string>;
  titles?: Maybe<string[]>;
  roles?: Maybe<string[]>;
  address?: Maybe<AddressInput>;
  firstName: string;
  lastName: string;
  tag?: Maybe<Tags>;
  isOrgAdmin?: Maybe<boolean>;
};

export type UpdateUserLinkedEhrUser = {
  id: string;
  linkedEhrUser?: Maybe<string>;
};

export type UpdateUserEmail = {
  externalId: string;
  email: string;
};

export type DeleteUserInput = {
  id: string;
  isAdmin: boolean;
};

export type ResendActivationMailInput = {
  id: string;
  email: string;
  userType: UserType;
};

export enum UserType {
  EhrUser = 'EHR_USER',
  VimEhrUser = 'VIM_EHR_USER',
  VimAdmin = 'VIM_ADMIN',
}

export enum AccountActivationStatus {
  PendingActivation = 'pendingActivation',
  PendingReSign = 'pendingReSign',
  Activated = 'activated',
}

export type SendPasswordResetEmailInput = {
  email: string;
  userType?: UserType;
  userTypes?: UserType[];
};

export type UpdateOrgUsersTinInput = {
  orgId: number;
  tin: Array<string>;
  replaceExisting: boolean;
};

export type MutationCreateConsoleVimSignatureArgs = {
  input?: Maybe<CreateConsoleVimSignatureInput>;
};

export type CreateConsoleVimSignatureInput = {
  appIds: string[];
  organizationId: number;
};

export type VimSignatureResponse = {
  signatures: Array<AppVimSignature>;
};

export type AppVimSignature = {
  appId: string;
  vimSignature: string;
};
