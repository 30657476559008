import React, { useEffect } from 'react';
import { Loader } from '@getvim/atomic-ui';
import { useAuth0Wrapper } from './useAuth0Wrapper';

export function Secure({ children }) {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0Wrapper();

  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) loginWithRedirect();
  }, [loginWithRedirect, isAuthenticated, isLoading]);

  if (isLoading) return <Loader className="center" />;

  return isAuthenticated && children;
}
